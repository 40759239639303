<template>
    <div class="container wapper">
        <!-- pc端导航 -->
        <div class="PCcontainer" v-show="full">
            <div style="width:1200px;margin:0 auto; display:flex;justify-content: space-between;">
                <div class="header" @click="gohome">
                    <img class="logo" src="../assets/img/logo.png"/>
                </div>

                <div class="flex_box mymakeinfo" style="position: relative;">
                    <div class="flex_bto" :class="active == item.id ? 'flex_active': ''"
                         v-for="(item,index) in menuList" :key="index" @click="Go(item.url)">{{item.name}}
                    </div>
                    <div class="top_tel" >
                        <div><span>TEL</span>:<a href="tel:18660515238">18660515238</a>（微信同号）</div>
                        <div><span>TEL</span>:<a href="tel:18663809796">18663809796</a>（微信同号）</div>
                    </div>
                </div>
            </div>


        </div>

        <!-- 移动端导航 -->
        <div class="movecontainer" v-show="!full">
            <div class="header" @click="gohome">
                <img class="movelogo" src="../assets/img/logo.png"/>
            </div>
            <div class="H5_item">
                <img class="menu" src="../assets/img/daohang.png" @click="make_menu"/>
            </div>
            <transition name="toggle-cart">
                <div class="H5-menu-item" v-show="!full&&!dismenu">
                    <div class="" v-for="(item,index) in menuList" @click="Go(item.url)" :key="index">{{item.name}}
                    </div>
                </div>
            </transition>
        </div>

        <!-- <div class="H5_item" v-show="!full">
          <svg-icon icon-class="list" @click="make_menu" class-name="H5-menu" />
        </div> -->
    </div>
</template>
<script>
    export default {
        name: 'Header',
        props: {
            full: {
                type: Boolean
            },
            active: {
                type: Number
            }
        },
        data() {
            return {
                // fullWidth:true,
                // active:1,
                menuList: [
                    {id: 1, name: "首页", url: '/'},
                    {id: 2, name: "创新产品", url: '/innovative'},
                    {id: 3, name: "解决方案", url: '/solution'},
                    {id: 4, name: "成功案例", url: '/case'},
                    {id: 5, name: "公司介绍", url: '/me'},
                    {id: 6, name: "防伪查询", url: '/search'}
                ],
                activeMenu: '',
                dismenu: true,
            }
        },
        created() {

        },
        methods: {
            make_menu() { //点击导航图标
                this.dismenu = !this.dismenu;
            },
            Go(url) {
                console.log(url)
                this.$router.push({path: url})
                if (!this.full) {
                    this.dismenu = !this.dismenu;
                }

            },
            gohome() {
                this.$router.push({path: '/'})
            }
        },
        mounted() {

        },
    }
</script>

<style scoped>
    .top_tel {
      position: absolute;
      top:13px;
      right: -220px;
      font-size: 14px;
      color:#555555;
    }
    .top_tel a{
        color:white;
        text-decoration: none;
    }
    .top_tel span{
        color:#FF0000;
    }


    .container {
        position: fixed;
        width: 100%;
        z-index: 9999;
        top: 0;
    }

    .PCcontainer {
        display: flex;
        background: #000000;
        z-index: 999;
        height: 75px;
        position: relative
    }

    .movecontainer {
        display: flex;
        background: #000000;
        z-index: 999;
        height: 52px;
        justify-content: space-between
    }

    .header {
        cursor: pointer;
        /*width: 29%;*/
        display: flex;
        align-items: center;
        justify-content: left;
    }

    .logo {
        height: 60px;

        display: block;
    }

    .logo_title {
        width: 180px;
        height: 42px;
        font-size: 30px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        margin-left: 8px;
        color: #FFFFFF;
        line-height: 42px;
    }

    .movelogo_title {
        width: 84px;
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        margin-left: 8px;
        color: #FFFFFF;
        line-height: 20px;
    }

    .movelogo {
        height: 42px;
        display: block;
    }

    .flex_box {
        display: flex;
        /*width: 75%;*/
        align-items: center;
    }

    .flex_bto {
        cursor: pointer;
        width: 80px;
        text-align: center;
        margin: 0 40px;
        height: 35px;
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 25px;
    }

    .flex_active {
        border-bottom: 4px solid #ff0000;
        width: 80px;
        height: 35px;
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #FF0000;
        line-height: 25px;

    }

    .H5_item {
        width: 50%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        /* width: 21px;
        height: 21px; */
    }

    .menu {
        width: 32px;
        height: 32px;
        margin-right: 20px;
    }

    .H5-menu-item {
        background: #000000;
        color: #fff;
        width: 100px;
        /* position: fixed; */
        position: absolute;
        z-index: 10;
        right: 0px;
        top: 52px;


    }

    .H5-menu-item div {
        height: 42px;
        line-height: 42px;
        text-align: center;
        margin: 12px 0;
        font-size: 15px;
    }

    .H5-menu-item div:hover {
        background: #fff;
        color: #FF0000;
    }

    .toggle-cart-enter-active,
    .toggle-cart-leave-active {
        transition: all 0.4s ease-out;
    }

    .toggle-cart-enter,
    .toggle-cart-leave-active {
        transform: translateX(100px);
    }

</style>
