<template>

<!-- 移动端 -->
  <div class="container wapper" >


    <div class="pc_back" style="width:100%;height:367px" v-show="full">
      <!-- <img style="width:100%" src="../assets/img/dibu.png" alt=""> -->
      <div class="pctop">
        
        <div class="top_left">
            <h2 class="title">关于我们</h2>
            <div class="text">24小时服务热线：17862881545</div>
            <div class="text">联系电话：18660515238</div>
            <div class="text">电子邮箱：lzh@sharp-eyes.cn</div>
            <div class="text">总部地址：山东省青岛是黄岛区映山红路688号</div>
            
        </div>
        <div class="top_left">
            <h2 class="title">产品于服务</h2>
            <div class="text">防伪验证</div>
            <div class="text">品质溯源</div>
            <div class="text">营销互动</div>
            <div class="text">渠道管控</div>
            
        </div>
        <div class="top_left">
            <h2 class="title">客户</h2>
            <div class="text">军民融合食品保障平台</div>
            <div class="text">东西协作平台</div>
            <div class="text">烟台中宠集团</div>
            <div class="text">烟台希雅斯酒庄</div>
            
        </div>
        <div class="top_left">
            <h2 class="title">扫码关注公众号</h2>
            <img class="pc_img" src="../assets/img/copy.jpg" alt="">
        </div>
      </div>
      <div class="copy">
        <a href="https://beian.miit.gov.cn/" class="pc_copy">Copyright © 2018-2021 鲁ICP备18034836号-1</a>
      </div>
      
    </div>



    <div class="move_back" v-show="!full">
      <div class="top">
        <div class="top_left">
            <h2 class="title">产品于服务</h2>
            <div class="text">防伪验证</div>
            <div class="text">品质溯源</div>
            <div class="text">营销互动</div>
            <div class="text">渠道管控</div>
            
        </div>
        <div class="top_right">
            <h2 class="title">关于我们</h2>
            <div class="text">24小时服务热线：17862881545</div>
            <div class="text">联系电话：18660515238</div>
            <div class="text">电子邮箱：lzh@sharp-eyes.cn</div>
            <div class="text">总部地址：山东省青岛是黄岛区映山红路688号</div>
            
        </div>
         
      </div>
      <div class="content">
        <!-- <div class="text">致力于构建智能化应用生态圈</div>
        <div class="text">为客户提供专业、成熟的行业解决方案于技术服务</div> -->
        <div class="text">专注于人工智能和区块链防伪追溯技术领域的前沿探索，致力于品牌及消费者合法权益保护。</div>
      </div>
      <div class="bottom">
        <a href="https://beian.miit.gov.cn/" class="pc_copy">Copyright © 2018-2021 鲁ICP备18034836号-1</a>
      </div>
    </div>
    
  </div>
</template>
<script>
export default {
  name: "Footer",
   props: {
    full: {
      type: Boolean
    },
    
  },
};
</script>

<style  scoped>
.container {
    position: relative;
    bottom: 0;
    width: 100%;
    height: 287px;
    
    display: flex;
    flex-direction:column;
  

}
.pc_back{background-image:url(../assets/img/dibu.png); background-repeat:no-repeat;background-size:100% 100%;-moz-background-size:100% 100%;display: flex;justify-content: center;align-items: center;}
.pc_img{width: 100px;height: 100px;}
.pc_copy{width: 243px;height: 17px;font-size: 12px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: #FFFFFF;line-height: 17px;}
.copy{position: absolute;bottom: 0;margin-bottom: 22px;}





.container .pc_back .pctop{ padding: 23px 26px 17px 23px;display: flex;}
.container .pctop .top_left{margin-right: 121px;width: 125px;}
.container .pctop .top_left .title{height: 23px;font-size: 16px;font-family: PingFangSC-Medium, PingFang SC;font-weight: 500;color: #FFFFFF;line-height: 22px;margin-bottom: 24px;}
.container .pctop .top_left .text{width: 199px;height: 17px;font-size: 12px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: #FFFFFF;line-height: 17px;margin-bottom: 15px;}



/* 移动 */

.move_back{background-image:url(../assets/img/mdibu.png);background-repeat:no-repeat;background-size:100% 100%;-moz-background-size:100% 100%;}
/* top */
.container .top{ padding: 23px 26px 17px 23px;display: flex;border-bottom: 1px solid #fff;}
.container .top .top_left{margin-right: 46px;width: 125px;}
.container .top .top_left .title{font-size: 14px;color: #fff;font-family: PingFangSC-Medium, PingFang SC;}
.container .top .top_left .text{margin: 11px 0;font-size: 12px;color: #fff;}
.container .top .top_right .title{font-size: 14px;color: #fff;font-family: PingFangSC-Medium, PingFang SC;}
.container .top .top_right .text{margin: 11px 0;font-size: 12px;color: #fff;}

/* content */
.container .content .text{font-size: 10px; color: #fff;}
.container .content{padding: 13px 0; padding: 16px 16px 13px 16px;}
/* bottom */
.container .bottom{position: relative;bottom: 0; width: 100%;}
.container .bottom .text{font-size: 10px;padding: 10px 0;text-align: center;color: #fff;}
</style>
