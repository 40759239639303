<template>
  <div>
    <Header :active=1 :full='fullWidth'/>
    <!-- 轮播图 -->
    <el-carousel :height="fullWidth == true ? '6.80rem' : '7.4rem'" style="margin-top:0.6rem" v-if="fullWidth">
      <el-carousel-item v-for="item in imgs" :key="item.id" >
        <el-image :src="item.url" style="width: 100%;height:100%;"></el-image>
      </el-carousel-item>
    </el-carousel>
    <!-- 轮播图h5 -->
    <el-carousel :height="fullWidth == true ? '6.80rem' : '7.4rem'" style="margin-top:2.4rem" v-if="!fullWidth">
      <el-carousel-item v-for="item in img" :key="item.id" >
        <el-image :src="item.url" style="width: 100%;height:100%;"></el-image>
      </el-carousel-item>
    </el-carousel>
    <!-- 解决方案PC -->
    <div v-show="fullWidth">
      <div class="title">
        <p>Solution</p>
        <span>解决方案</span>
        <div></div>
      </div>
      <ul class="solutionPC">
        <li>
          <img src="../assets/shouye/食品酒水.png" alt="">
          <div>
            <h5>食品酒水行业</h5>
              <span>
                <b style="font-size: 20px;font-weight: bold">·</b> 结合产品包装实现标签赋码，防伪与产品完美结合<br/>
                 <b style="font-size: 20px;font-weight: bold">·</b> 融合多种防伪技术应用，提高产品防伪系数
              </span>
          </div>
        </li>
        <li>
          <img src="../assets/shouye/医药品行业.png" alt="">
          <div>
            <h5>医药品行业</h5>
            <span> <b style="font-size: 20px;font-weight: bold">·</b> 融合多项高级防伪技术，提高产品防伪系数。<br/>
               <b style="font-size: 20px;font-weight: bold">·</b>建立产品全流程管理体系，实现生产管控、市场调配、防窜货预警。</span>
          </div>
        </li>
        <li>
          <div>
            <h5>日化用品行业</h5>
            <span>
               <b style="font-size: 20px;font-weight: bold">·</b>提供精美优质标签，提高产品形象。<br/>
               <b style="font-size: 20px;font-weight: bold">·</b>结合产品营销方案、提高产品复购率、美誉度。</span>
          </div>
          <img src="../assets/shouye/日化防伪.png" alt="">
        </li>
        <li>
          <div>
            <h5>农产品行业</h5>
            <span> <b style="font-size: 20px;font-weight: bold">·</b>针对性一物一码赋码方案保护二维码信息，抗破坏性强。<br/>
               <b style="font-size: 20px;font-weight: bold">·</b> 保障产品全流程溯源采集操作简便，数据准确。</span>
          </div>
          <img src="../assets/shouye/全行业通用.png" alt="">
        </li>
      </ul>
    </div>
    <!-- 解决方案h5 -->
    <div v-show="!fullWidth">
      <div class="title">
        <p>Solution</p>
        <span>解决方案</span>
        <div></div>
      </div>
      <ul class="solutionH5">
        <li>
          <img src="../assets/shouye/食品酒水.png" alt="">
          <div>
            <h5>食品酒水行业</h5><span>
            <b style="font-size: 20px;font-weight: bold">·</b> 结合产品包装实现标签赋码，防伪与产品完美结合<br/>
            <b style="font-size: 20px;font-weight: bold">·</b> 融合多种防伪技术应用，提高产品防伪系数
          </span>

          </div>
        </li>
        <li>
          <div>
            <h5>日化用品行业</h5>
            <span>
               <b style="font-size: 20px;font-weight: bold">·</b>提供精美优质标签，提高产品形象。<br/>
               <b style="font-size: 20px;font-weight: bold">·</b>结合产品营销方案、提高产品复购率、美誉度。</span>
          </div>
          <img src="../assets/shouye/日化防伪.png" alt="">
        </li>
        <li>
          <img src="../assets/shouye/医药品行业.png" alt="">
          <div>
            <h5>医药品行业</h5>
            <span> <b style="font-size: 20px;font-weight: bold">·</b> 融合多项高级防伪技术，提高产品防伪系数。<br/>
               <b style="font-size: 20px;font-weight: bold">·</b>建立产品全流程管理体系，实现生产管控、市场调配、防窜货预警。</span>
          </div>
        </li>
        <li>
          <div>
            <h5>农产品行业</h5>
            <span> <b style="font-size: 20px;font-weight: bold">·</b>针对性一物一码赋码方案保护二维码信息，抗破坏性强。<br/>
               <b style="font-size: 20px;font-weight: bold">·</b> 保障产品全流程溯源采集操作简便，数据准确。</span>
          </div>
          <img src="../assets/shouye/全行业通用.png" alt="">
        </li>
      </ul>
    </div>
    <!-- 业务介绍PC -->
    <div style="background:#F8F8F8;padding-top:20px;" v-show="fullWidth">
      <div class="title" style="width:50%;">
        <p>Business introduction</p>
        <span>业务介绍</span>
        <div></div>
      </div>
      <ul class="businessIntroduction-PC">
        <li>
          <img src="../assets/shouye/icon1.svg" alt="">
          <h5>防伪验证</h5>
          <span>一物一码验证产品真伪</span>
        </li>
        <li>
          <img src="../assets/shouye/icon2.svg" alt="">
          <h5>品质溯源</h5>
          <span>产品生命周期全流程追溯</span>
        </li>
        <li>
          <img src="../assets/shouye/icon3.svg" alt="">
          <h5>营销互动</h5>
          <span>智能化、多元化营销</span>
        </li>
        <li>
          <img src="../assets/shouye/icon4.svg" alt="">
          <h5>渠道管控</h5>
          <span>渠道体系全程可视、稳定可控</span>
        </li>
      </ul>
    </div>
    <!-- 业务介绍h5 -->
    <div style="background:#F8F8F8;padding-top:20px;" v-show="!fullWidth">
      <div class="title" style="width:50%;">
        <p>Business introduction</p>
        <span>业务介绍</span>
        <div></div>
      </div>
      <ul class="businessIntroduction-H5">
        <li>
          <img src="../assets/shouye/icon1.svg" alt="">
          <h5>防伪验证</h5>
          <span>一物一码验证产品真伪</span>
        </li>
        <li>
          <img src="../assets/shouye/icon2.svg" alt="">
          <h5>品质溯源</h5>
          <span>产品生命周期全流程追溯</span>
        </li>
        <li>
          <img src="../assets/shouye/icon3.svg" alt="">
          <h5>营销互动</h5>
          <span>智能化、多元化营销</span>
        </li>
        <li>
          <img src="../assets/shouye/icon4.svg" alt="">
          <h5>渠道管控</h5>
          <span>渠道体系全程可视、稳定可控</span>
        </li>
      </ul>
    </div>
    <!-- 创新产品pc -->
    <div class="innovationProduct-PC" v-show="fullWidth">
      <div class="out">
        <ul class="top">
          <li>
            <h5><span>15</span>年</h5>
            <p>印刷经验</p>
          </li>
          <li>
            <h5><span>10</span>年</h5>
            <p>互联网开发经验</p>
          </li>
          <li>
            <h5><span>10</span>年</h5>
            <p>市场沉淀</p>
          </li>
        </ul>
      </div>
      <div class="bottom" style="height:647px;">
        <div class="title">
          <p >Innovative product</p>
          <span style="color:#fff;">创新产品</span>
          <div></div>
        </div>
        <el-carousel :interval="5000" arrow="always" :height="fullWidth?'412px':''" style="width: 878px;margin: 0 auto;border-radius: 10px;" >
          <el-carousel-item v-for="item in productImgs" :key="item.id" style="background:#fff;display: flex;padding:30px;align-items:center;">
            <img :src="item.url" alt="" style="width: 331px;height: 303px;">
            <div style="font-size:14px;margin-left:20px;">
              <!-- <h4 style="margin-bottom:20px;">{{item.name}}</h4> -->
              <span style="line-height:34px;">{{item.con}}</span>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <!-- 创新产品h5 -->
    <div class="innovationProduct-H5" v-show="!fullWidth">
      <ul class="top"></ul>
      <div class="bottom">
        <div class="title" style="width: 45%;">
          <p>Innovative product</p>
          <span  style="color:#fff;">创新产品</span>
          <div></div>
        </div>
        <el-carousel :interval="5000" arrow="never"  style="width: 90%;margin: 0 auto;border-radius: 10px;" indicator-position="none">
          <el-carousel-item v-for="item in productImgs" :key="item.id" style="background:#fff;display: flex;padding:10px;border-radius: 10px;height: 180px;">
            <img :src="item.url" alt="" style="width: 47%;height: 100%;">
            <div style="font-size:11px;margin-left:5px;width:53%;">
              <!-- <h4 class="innovationProduct_h4">{{item.name}}</h4> -->
              <span class="innovationProduct_span">{{item.con}}</span>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <!-- 成功案例pc -->
    <div class="success-PC" v-show="fullWidth" >
      <div class="title" style="margin-top:60px;margin-bottom: 95px;">
        <p>Success cases</p>
        <span>成功案例</span>
        <div></div>
      </div>
      <ul class="bottom">
        <li>
          <img src="../assets/shouye/img1.png" alt="">
        </li>
        <li>
          <img src="../assets/shouye/img.png" alt="">
        </li>
        <li>
          <img src="../assets/shouye/img2.png" alt="">
        </li>
        <li>
          <img src="../assets/shouye/img3.png" alt="">
        </li>
      </ul>
    </div>
    <!-- 成功案例h5 -->
    <div class="success-H5" v-show="!fullWidth" >
      <div class="title" style="width:50%;">
        <p>Success cases</p>
        <span>成功案例</span>
        <div></div>
      </div>
      <ul class="bottom">
        <li>
          <img src="../assets/shouye/img1.png" alt="">
        </li>
        <li>
          <img src="../assets/shouye/img.png" alt="">
        </li>
        <li>
          <img src="../assets/shouye/img2.png" alt="">
        </li>
        <li>
          <img src="../assets/shouye/img3.png" alt="">
        </li>
      </ul>
    </div>
    <!-- 关于火眼防伪pc -->
    <div class="me_center" v-show="fullWidth" style="background:#F8F8F8;padding:30px 0;">
      <div class="title" style="width:50%;">
        <p>About Us</p>
        <span style="margin:10px 0;">关于火眼防伪</span>
        <div></div>
      </div>
      <div class="center_bottom">
        <div class="bottom_left"></div>
        <div class="bottom_right">
          <span style="font-size:14px;line-height: 48px;">
        烟台火眼防伪科技有限责任公司成立于2018年， 总部设在中国烟台，是山东省内首家防伪科技公司， 专注于新一代人工智能和区块链防伪追溯技术应用（主营）， 致力于品牌及消费者合法权益保护。 火眼防伪科技旗下的【火眼防伪实验室】， 是由互联网和区块链技术专家、济南大学人工智能研究院的人工智能学者教授以及包装印刷工艺专家团队组成， 专注于人工智能和区块链防伪追溯技术领域的前沿探索。
          </span>
        </div>
      </div>
    </div>
    <!-- 关于火眼防伪h5 -->
    <div class="move_center" v-show="!fullWidth" style="background:#F8F8F8;padding:30px 0;">
      <div class="title" style="width:100%;">
        <p style="font-size: 10px;line-height: 30px">About Us</p>
        <span>关于火眼防伪</span>
        <div></div>
      </div>
      <div class="move_bottom">
        <div class="movebottom_left" >
        </div>
        <div class="move_right">
          <span>
            烟台火眼防伪科技有限责任公司成立于2018年， 总部设在中国烟台，是山东省内首家防伪科技公司， 专注于新一代人工智能和区块链防伪追溯技术应用（主营）， 致力于品牌及消费者合法权益保护。 火眼防伪科技旗下的【火眼防伪实验室】， 是由互联网和区块链技术专家、济南大学人工智能研究院的人工智能学者教授以及包装印刷工艺专家团队组成， 专注于人工智能和区块链防伪追溯技术领域的前沿探索。
          </span>
        </div>
      </div>
    </div>
    <!-- 底部 -->
    <Footer :full='fullWidth'/>
  </div>
</template>

<script>
// @ is an alias to /src

import  Header from "@/components/Header.vue";
import  Footer from "@/components/Footer.vue";
export default {
  name: "Home",
  components: {
    Header,
    Footer
  },

  data(){
    return{
      fullWidth:true,//屏幕宽度
      // 轮播图
      imgs:[
        {id:1,url:require('../assets/img/carousel/carousel1.jpeg')},
      ],
       img:[
        {id:1,url:require('../assets/shouye/bannerH5.png')},
      ],
      // 创新产品轮播图
      productImgs:[
        {
          id:1,
          url:require('../assets/shouye/fang.png'),
          name:'火眼防伪实验室',
          con:'火眼防伪追溯系统是国内技术领先的防伪追溯平台，也是国内少数可实现“不可复制/不可篡改”的顶级防伪追溯标准的平台之一。可实现基于一物一码和人工智能以及区块链技术对商品的生产、仓储、运输、销售及市场管理等等环节进行商品全生命周期的数字化-智能化跟踪管理，不仅通过人工智能和生物识别技术从制造源头杜绝伪造仿冒，更通过区块链技术做到商品流通全过程的可视化追溯。在保护消费者合法权益的同时，也充分保护和提升品牌价值。'
        },
        // {
        //   id:2,
        //   url:require('../assets/innovative/background.png'),
        //   name:'火眼防伪实验室',
        //   con:'推出的火眼防伪追溯系统是国内技术领先的防伪追溯平台，也是国内少数可实现“不可复制/不可篡改”的顶级防伪追溯标准的平台之一。可实现基于一物一码和人工智能以及区块链技术对商品的生产、仓储、运输、销售及市场管理等等环节进行商品全生命周期的数字化-智能化跟踪管理，不仅通过人工智能和生物识别技术从制造源头杜绝伪造仿冒，更通过区块链技术做到商品流通全过程的可视化追溯。在保护消费者合法权益的同时，也充分保护和提升品牌价值。'
        // }
      ],
      height:510,
    }
  },
  created(){
  },
  methods:{
      page_width() {//获取屏幕宽度
        let screenWidth = window.screen.width;
        // console.log(screenWidth);
        if (screenWidth < 800) {
          this.fullWidth = false;
        } else {
          this.fullWidth = true;
        }
        var h = 510
        if(screenWidth > 1440){
          // this.height = 0
          this.height =  h + h*0.35
          console.log('加',this.height)
          //
        }else{
          console.log('减',this.height)
          // this.height = 0
          this.height = h - h*0.35
        }
      },
      setRem() {
        var whdef = 100/1920;// 表示1920的设计图,使用100PX的默认值
        var bodyWidth = document.body.clientWidth;// 当前窗口的宽度
        var rem = bodyWidth * whdef;// 以默认比例值乘以当前窗口宽度,得到该宽度下的相应FONT-SIZE值
        document.getElementsByTagName('html')[0].style.fontSize = rem + 'px';
      }
  },
  mounted(){
    window.onresize = () => {//监听屏幕变化
      this.page_width();
      this.setRem()
    };
    this.page_width();
    this.setRem()
  },

};
</script>
<style scoped lang="less">
// 标题
.title{
  width: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 30px auto;
  p{
    color: #E02222;
    font-size: 18px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
  }
  span{
    display: block;
    font-size: 16px;
  }
  div{
    background-color: #DDDDDD;
    width: 64px;
    height: 5px;
    border-radius: 50px;
    margin-top: 10px;
  }
}
// 解决方案pc端
.solutionPC{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 1200px;
  margin:0 auto;
  padding-bottom: 30px;
  list-style: none;
  li{
    width: 50%;
    display: flex;
    img{
      width: 50%;
      height: 200px;
    }
    div{
      width: 50%;
      padding: 20px;
      line-height: 3px;
      height: 200px;
      h5{
        font-size: 18px;
        text-align: center;
      }
      span{
        display: block;
        margin-top: 15px;
        font-size: 16px;
        color: #595959;
        line-height: 20px;
      }
    }
  }
}
// 解决方案h5端
.solutionH5{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 20px;
  list-style: none;
  li{
    width: 90%;
    display: flex;
    img{
      width: 50%;
      height: 127px;
    }
    div{
      width: 50%;
      padding: 10px;
      height: 120px;
      line-height: 20px;
      h5{
        width: 120px;
        height: 14px;
        font-size: 10px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #000000;
        line-height: 14px;
        text-align: center;
      }
      span{
        width: 144px;
        font-size: 11px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #595959;
        line-height: 16px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 5;
        overflow: hidden;
      }
    }
  }
}
// 业务介绍pc端
.businessIntroduction-PC{
  display: flex;
  justify-content: center;
  list-style: none;
  background: #F8F8F8;
  width: 1200px;
  margin: 0 auto;
  padding-bottom: 30px;
  li{
    background: #FDFDFD;
    width: 24%;
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    margin: 0 10px;
    img{
      width: 90px;
      height: 90px;
      margin-bottom: 15px;
    }
    h5{
      font-size: 20px;
    }
    span{
      font-size: 19px;
      color: #252525;
    }
  }
}
// 业务介绍H5端
.businessIntroduction-H5{
  display: flex;
  list-style: none;
  width: 100%;
  margin: 0 auto;
  padding:0 5px;
  padding-bottom: 20px;
  li{
    flex-direction: column;
    width: 25%;
    align-items: center;
    text-align: center;
    background: #fff;
    justify-content: center;
    margin: 0 3px;
    img{
      width:13px;
      height: 18px;
    }
    h5{
      margin-top: 11px;
      font-size: 8px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #000000;
      line-height: 8px;
    }
    span{
      margin-top: 5px;
      font-size: 6px;
      font-family: PingFangSC-Light, PingFang SC;
      font-weight: 300;
      color: #252525;
      display: block;
      overflow-wrap:break-word;
    }
  }
}
// 创新产品PC端
.innovationProduct-PC{
  // /deep/.el-carousel__arrow{
  //   display: none;
  // }
  .out{
    width: 100%;
    background-image: url('../assets/shouye/background1.png');
    background-size: 100% 100%;
    .top{
      height: 244px;
      width: 1200px;
      margin: 0 auto;
      display: flex;
      list-style:none;
      justify-content: space-between;
      align-items: center;
      padding: 0 200px;
      li{
        color: #fff;
        text-align: center;
        h5{
          font-size: 23px;
          span{
            font-size: 54px;
          }
        }
        p{
          font-size: 16px;
        }
      }
    }
  }
  .bottom{
    background-image: url('../assets/shouye/background2.png');
    background-size: 100% 100%;
    padding: 30px 0;
  }
}
// 创新产品h5端
.innovationProduct-H5{
  /deep/.el-carousel__indicator .el-carousel__indicator--horizontal .is-active button{
    background: #E02222;
  }
  /deep/.el-carousel__arrow .el-carousel__arrow--left{
    display: none;
  }
  /deep/.el-carousel__arrow .el-carousel__arrow--right{
    display: none;
  }
  .top{
    background-image: url('../assets/shouye/background1.png');
    height: 59px;
    width: 100%;
    background-size: 100% 100%;
  }
  .bottom{
    background-image: url('../assets/shouye/background2.png');
    background-size: 100% 100%;
    padding: 20px 0;
  }
  .innovationProduct_h4{
    width: 57px;
    height: 11px;
    font-size: 8px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #000000;
    line-height: 11px;
  }
  .innovationProduct_span{
    font-size: 9px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #111111;
  }
}
// 成功案例pc端
.success-PC{
  height: 478px;
  .bottom{
    list-style: none;
    display: flex;
    width:1200px;
    margin:0 auto;
    text-align: center;
    justify-content: center;
    li{
      width:247PX;
      height:247px;
      // box-shadow: 2px 5px 14px 1px #999;
      margin: 6px;
      display: flex;
      align-items: center;
      justify-content: center;
      img{
        width:100%;
        height: 100%;
      }
    }
  }
}
// 成功案例h5端
.success-H5{
  .bottom{
    list-style: none;
    display: flex;
    // width:90%;
    margin:0 auto;
    text-align: center;
    justify-content: center;
    li{
      width:83px;
      height:83px;
      // box-shadow: 2px 5px 14px 1px #999;
      margin: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      img{
        width:100%;
        height: 100%;
      }
    }
  }
}
// 关于火眼防伪pc
.center_bottom{display: flex;width: 1200px; justify-content: center; margin: 0 auto;height: 364px;}
.bottom_left{position: relative;width: 40%;height: 323px;   background-image:url(../assets/img/me/me.png);background-repeat:no-repeat;background-size:100% 100%;-moz-background-size:100% 100%;}
.left_img{margin-left: 40%;position: absolute;z-index: 999;}
.left_float{width: 40%;height: 212px;background: #E02222;position: absolute;bottom: 0;}
.bottom_right{width: 472px;height: 330px;font-size: 18px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: #252525;line-height: 33px;margin-left: 90px;}
//关于火眼防伪h5
.move_bottom{display: flex;margin-top: 22px;}
.movebottom_left{position: relative;width: 49%;height: 136px; background-image:url(../assets/img/me/movegongsi.png);background-repeat:no-repeat;background-size:100% 100%;-moz-background-size:100% 100%;}
.move_img{width: 177px;height: 136px;position: absolute;z-index: 999;margin-left: 15px;}
.move_float{width: 159px;height: 89px;background: #E02222;position: absolute;bottom: 0;}
.move_right{width: 50%;font-size: 10px;font-family: PingFangSC-Regular, PingFang SC;font-weight: 400;color: #252525;margin-left: 10px;padding-right: 10px}

</style>
